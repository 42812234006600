import React from 'react'
import "./styles/styles.css"
import PITS from './components/PITS'

const App = () => {
  return (
    <div className='all'>
      <PITS />
    </div>
  )
}

export default App